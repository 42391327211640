import { useRouter } from "next/navigation";
import type { MouseEvent} from "react";
import { useCallback } from "react";
import type { AlertColor } from "@mui/material/Alert";
import { objectToSearchParamsString } from "@rimo/frontend/hooks/useNavigation";
import { Constant } from "../constants/constant";

const publicUrlList = ["/about", "/login", Constant.Url.Internal.Logout, "/thankyou"] as const;

type PublicUrl = (typeof publicUrlList)[number];

export function isPublicPage(pathname: unknown): pathname is PublicUrl {
  if (pathname === "/about") return true;
  if (pathname === "/login") return true;
  if (pathname === Constant.Url.Internal.Logout) return true;
  if (pathname === "/thankyou") return true;
  return false;
}

export type LogoutProps = Partial<{
  /** アラートの色 */
  severity: AlertColor;
  /** ログアウト時文言 */
  message: string;
  /** リダイレクトURLパス */
  redirect: PublicUrl;
  /** 通知をミュート */
  mute: true;
}>;
export const useAuth = () => {
  const router = useRouter();

  const logout = useCallback(
    (query?: LogoutProps | undefined | MouseEvent<HTMLElement>) => {
      // alias as onClick props
      if (typeof query === "object" && "preventDefault" in query) {
        query.preventDefault();
        router.push(Constant.Url.Internal.Logout);
      } else {
        const queryString = typeof query === "object" ? objectToSearchParamsString(query) : "";
        router.push(`${Constant.Url.Internal.Logout}${queryString}`);
      }
    },
    [router]
  );

  return { logout };
};
