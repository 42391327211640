"use client";

import type { FC, PropsWithChildren } from "react";

import { CookiesProvider } from "react-cookie";

import { UserProvider } from "./UserContext";
import { TeamProvider } from "./TeamContext";
import { PopupsProvider } from "./PopupsContext";
import { NotificationProvider } from "./NotificationContext";
import { VoiceMediaProvider } from "./VoiceMediaContext";
import { DownloadProvider } from "./DownloadContext";
import { PaymentProvider } from "./PaymentContext";
import { ErrorPageProvider } from "./ErrorPageContext";
import { OrganizationProvider } from "./OrganizationContext";
import { OrganizationControlledProvider } from "./OrganizationControlledContext";
import { VoiceInfoContextProvider } from "./VoiceInfoContext";
import { LandingPageProvider } from "./LandingPageContext";
import { RoomProvider } from "./RoomContext";
import { RecordingProvider } from "./RecordingContext";
import { OrgUserContextProvider } from "./OrganizationUserContext";
import { LocaleProvider } from "./LocaleProvider";
import type { AllLanguageCodes } from "@rimo/i18n/settings";

export const RimoContextsProvider: FC<PropsWithChildren<{ locale: AllLanguageCodes }>> = ({ children, locale }) => {
  return (
    <CookiesProvider>
      <NotificationProvider>
        <UserProvider>
          <VoiceInfoContextProvider>
            <LocaleProvider initialLocale={locale}>
              <OrganizationProvider>
                <OrganizationControlledProvider>
                  <OrgUserContextProvider>
                    <TeamProvider>
                      <PopupsProvider>
                        <PaymentProvider>
                          <DownloadProvider>
                            <VoiceMediaProvider>
                              <LandingPageProvider>
                                <RoomProvider>
                                  <RecordingProvider>
                                    <ErrorPageProvider>{children}</ErrorPageProvider>
                                  </RecordingProvider>
                                </RoomProvider>
                              </LandingPageProvider>
                            </VoiceMediaProvider>
                          </DownloadProvider>
                        </PaymentProvider>
                      </PopupsProvider>
                    </TeamProvider>
                  </OrgUserContextProvider>
                </OrganizationControlledProvider>
              </OrganizationProvider>
            </LocaleProvider>
          </VoiceInfoContextProvider>
        </UserProvider>
      </NotificationProvider>
    </CookiesProvider>
  );
};

export const SecurityRestrictedProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <CookiesProvider>
      <UserProvider>
        <VoiceInfoContextProvider>
          <OrganizationProvider>
            <OrganizationControlledProvider>
              <PopupsProvider>
                <PaymentProvider>
                  <VoiceMediaProvider>
                    <ErrorPageProvider>{children}</ErrorPageProvider>
                  </VoiceMediaProvider>
                </PaymentProvider>
              </PopupsProvider>
            </OrganizationControlledProvider>
          </OrganizationProvider>
        </VoiceInfoContextProvider>
      </UserProvider>
    </CookiesProvider>
  );
};
