/**
 * Generated by orval v7.4.0 🍺
 * Do not edit manually.
 * RimoVoice Service API
 * OpenAPI spec version: 0.0.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  BatchImport,
  BatchImportTeamMemberPreviewRequest,
  BatchImportTeamMemberRequest,
  BatchImportTeamMemberResponse,
  Team,
  TeamCreate,
  TeamMember,
  TeamMembersListParams,
} from ".././models";
import { customInstance } from "../../api/customInstance";
import type { ErrorType, BodyType } from "../../api/customInstance";

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const teamMembersBatchImportExecute = (
  teamId: string,
  batchImportTeamMemberRequest: BodyType<BatchImportTeamMemberRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<BatchImportTeamMemberResponse>(
    {
      url: `/organizations/${teamId}/members/batch_import`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: batchImportTeamMemberRequest,
    },
    options
  );
};

export const getTeamMembersBatchImportExecuteMutationFetcher = (
  teamId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return (_: Key, { arg }: { arg: BatchImportTeamMemberRequest }): Promise<BatchImportTeamMemberResponse> => {
    return teamMembersBatchImportExecute(teamId, arg, options);
  };
};
export const getTeamMembersBatchImportExecuteMutationKey = (teamId: string) =>
  [`/organizations/${teamId}/members/batch_import`] as const;

export type TeamMembersBatchImportExecuteMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamMembersBatchImportExecute>>
>;
export type TeamMembersBatchImportExecuteMutationError = ErrorType<unknown>;

export const useTeamMembersBatchImportExecute = <TError = ErrorType<unknown>>(
  teamId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof teamMembersBatchImportExecute>>,
      TError,
      Key,
      BatchImportTeamMemberRequest,
      Awaited<ReturnType<typeof teamMembersBatchImportExecute>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getTeamMembersBatchImportExecuteMutationKey(teamId);
  const swrFn = getTeamMembersBatchImportExecuteMutationFetcher(teamId, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const teamMembersBatchImportPreview = (
  teamId: string,
  batchImportTeamMemberPreviewRequest: BodyType<BatchImportTeamMemberPreviewRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<BatchImport>(
    {
      url: `/organizations/${teamId}/members/batch_import/preview`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: batchImportTeamMemberPreviewRequest,
    },
    options
  );
};

export const getTeamMembersBatchImportPreviewMutationFetcher = (
  teamId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return (_: Key, { arg }: { arg: BatchImportTeamMemberPreviewRequest }): Promise<BatchImport> => {
    return teamMembersBatchImportPreview(teamId, arg, options);
  };
};
export const getTeamMembersBatchImportPreviewMutationKey = (teamId: string) =>
  [`/organizations/${teamId}/members/batch_import/preview`] as const;

export type TeamMembersBatchImportPreviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamMembersBatchImportPreview>>
>;
export type TeamMembersBatchImportPreviewMutationError = ErrorType<unknown>;

export const useTeamMembersBatchImportPreview = <TError = ErrorType<unknown>>(
  teamId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof teamMembersBatchImportPreview>>,
      TError,
      Key,
      BatchImportTeamMemberPreviewRequest,
      Awaited<ReturnType<typeof teamMembersBatchImportPreview>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getTeamMembersBatchImportPreviewMutationKey(teamId);
  const swrFn = getTeamMembersBatchImportPreviewMutationFetcher(teamId, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const teamsCreate = (teamCreate: BodyType<TeamCreate>, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Team>(
    { url: `/teams`, method: "POST", headers: { "Content-Type": "application/json" }, data: teamCreate },
    options
  );
};

export const getTeamsCreateMutationFetcher = (options?: SecondParameter<typeof customInstance>) => {
  return (_: Key, { arg }: { arg: TeamCreate }): Promise<Team> => {
    return teamsCreate(arg, options);
  };
};
export const getTeamsCreateMutationKey = () => [`/teams`] as const;

export type TeamsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof teamsCreate>>>;
export type TeamsCreateMutationError = ErrorType<unknown>;

export const useTeamsCreate = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof teamsCreate>>,
    TError,
    Key,
    TeamCreate,
    Awaited<ReturnType<typeof teamsCreate>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getTeamsCreateMutationKey();
  const swrFn = getTeamsCreateMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const teamsRead = (teamId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Team>({ url: `/teams/${teamId}`, method: "GET" }, options);
};

export const getTeamsReadKey = (teamId: string) => [`/teams/${teamId}`] as const;

export type TeamsReadQueryResult = NonNullable<Awaited<ReturnType<typeof teamsRead>>>;
export type TeamsReadQueryError = ErrorType<unknown>;

export const useTeamsRead = <TError = ErrorType<unknown>>(
  teamId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof teamsRead>>, TError> & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!teamId;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getTeamsReadKey(teamId) : null));
  const swrFn = () => teamsRead(teamId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const teamsUpdate = (
  teamId: string,
  team: BodyType<NonReadonly<Team>>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Team>(
    { url: `/teams/${teamId}`, method: "PUT", headers: { "Content-Type": "application/json" }, data: team },
    options
  );
};

export const getTeamsUpdateMutationFetcher = (teamId: string, options?: SecondParameter<typeof customInstance>) => {
  return (_: Key, { arg }: { arg: NonReadonly<Team> }): Promise<Team> => {
    return teamsUpdate(teamId, arg, options);
  };
};
export const getTeamsUpdateMutationKey = (teamId: string) => [`/teams/${teamId}`] as const;

export type TeamsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof teamsUpdate>>>;
export type TeamsUpdateMutationError = ErrorType<unknown>;

export const useTeamsUpdate = <TError = ErrorType<unknown>>(
  teamId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof teamsUpdate>>,
      TError,
      Key,
      NonReadonly<Team>,
      Awaited<ReturnType<typeof teamsUpdate>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getTeamsUpdateMutationKey(teamId);
  const swrFn = getTeamsUpdateMutationFetcher(teamId, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const teamsDelete = (teamId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/teams/${teamId}`, method: "DELETE" }, options);
};

export const getTeamsDeleteMutationFetcher = (teamId: string, options?: SecondParameter<typeof customInstance>) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return teamsDelete(teamId, options);
  };
};
export const getTeamsDeleteMutationKey = (teamId: string) => [`/teams/${teamId}`] as const;

export type TeamsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof teamsDelete>>>;
export type TeamsDeleteMutationError = ErrorType<unknown>;

export const useTeamsDelete = <TError = ErrorType<unknown>>(
  teamId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof teamsDelete>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof teamsDelete>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getTeamsDeleteMutationKey(teamId);
  const swrFn = getTeamsDeleteMutationFetcher(teamId, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const teamMembersList = (
  teamId: string,
  params?: TeamMembersListParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<TeamMember[] | null>({ url: `/teams/${teamId}/members`, method: "GET", params }, options);
};

export const getTeamMembersListKey = (teamId: string, params?: TeamMembersListParams) =>
  [`/teams/${teamId}/members`, ...(params ? [params] : [])] as const;

export type TeamMembersListQueryResult = NonNullable<Awaited<ReturnType<typeof teamMembersList>>>;
export type TeamMembersListQueryError = ErrorType<unknown>;

export const useTeamMembersList = <TError = ErrorType<unknown>>(
  teamId: string,
  params?: TeamMembersListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof teamMembersList>>, TError> & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!teamId;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getTeamMembersListKey(teamId, params) : null));
  const swrFn = () => teamMembersList(teamId, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
