/**
 * Generated by orval v7.4.0 🍺
 * Do not edit manually.
 * RimoVoice Service API
 * OpenAPI spec version: 0.0.0
 */
import useSwr from "swr";
import type { Key, SWRConfiguration } from "swr";
import type { UserProfile } from ".././models";
import { customInstance } from "../../api/customInstance";
import type { ErrorType } from "../../api/customInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const userProfilesRead = (
  organizationId: string,
  userId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserProfile>(
    { url: `/organizations/${organizationId}/users/${userId}`, method: "GET" },
    options
  );
};

export const getUserProfilesReadKey = (organizationId: string, userId: string) =>
  [`/organizations/${organizationId}/users/${userId}`] as const;

export type UserProfilesReadQueryResult = NonNullable<Awaited<ReturnType<typeof userProfilesRead>>>;
export type UserProfilesReadQueryError = ErrorType<unknown>;

export const useUserProfilesRead = <TError = ErrorType<unknown>>(
  organizationId: string,
  userId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof userProfilesRead>>, TError> & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(organizationId && userId);
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getUserProfilesReadKey(organizationId, userId) : null));
  const swrFn = () => userProfilesRead(organizationId, userId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
