import React, { useReducer, useContext } from "react";
import type { Card } from "../api/payment";
import { isDev } from "../utils/system";

interface StateProps {
  loaded: boolean;
  cards: Card[];
  actions: string[];
}
interface ActionProps {
  type: ActionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

type ActionType = "SET_CARDS" | "PUT_ACTION_NONE" | "PUT_ACTION_RECAPTURE";

const reducer: React.Reducer<StateProps, ActionProps> = (state, { type, payload }): StateProps => {
  if (isDev) {
    console.group("State(Payment)");
    console.log("type: ", type);
    console.log("current: ", state);
    console.log("payload: ", payload);
    console.groupEnd();
  }

  switch (type) {
    case "SET_CARDS":
      return {
        ...state,
        loaded: true,
        cards: payload.cards,
      };
    case "PUT_ACTION_NONE":
      return {
        ...state,
        actions: [],
      };
    case "PUT_ACTION_RECAPTURE":
      return {
        ...state,
        actions: ["recapture"],
      };
    default:
      return state;
  }
};

const initialState: StateProps = {
  loaded: false,
  cards: [],
  actions: [],
};

export function usePaymentState() {
  return useContext(PaymentStateContext);
}

export function useSetPaymentState() {
  return useContext(PaymentDispatchContext);
}

export const PaymentStateContext = React.createContext<StateProps>(initialState);

const PaymentDispatchContext = React.createContext<React.Dispatch<ActionProps>>((() => {
  // noop
}) as React.Dispatch<ActionProps>);

export const PaymentProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PaymentStateContext.Provider value={state}>
      <PaymentDispatchContext.Provider value={dispatch}>{children}</PaymentDispatchContext.Provider>
    </PaymentStateContext.Provider>
  );
};
