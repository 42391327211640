import type { FC } from "react";
import { createContext, useContext } from "react";

import { UserContext } from "./UserContext";
import { useOrganization } from "./OrganizationControlledContext";
import { useUserProfilesRead } from "../api-client/user/user";
import type { UserProfile } from "../api-client/models";

type OrgUserContextType = {
  isLoaded: boolean;
  orgUser: UserProfile | undefined;
};

const orgUserContextInitialState: OrgUserContextType = {
  isLoaded: false,
  orgUser: undefined,
};

const OrgUserContext = createContext<OrgUserContextType>(orgUserContextInitialState);

type OrgUserRefreshContextType = () => Promise<UserProfile | null | undefined>;

const OrgUserRefreshContext = createContext<OrgUserRefreshContextType>(() => Promise.resolve(null));

export const OrgUserContextProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const {
    userState: { user },
  } = useContext(UserContext);

  const org = useOrganization();

  const { data, isLoading, mutate } = useUserProfilesRead(org?.id ?? "", user?.uid ?? "", {
    swr: { enabled: !!org && !!user },
  });

  return (
    <OrgUserContext.Provider value={{ isLoaded: !isLoading, orgUser: data }}>
      <OrgUserRefreshContext.Provider value={mutate}>{children}</OrgUserRefreshContext.Provider>
    </OrgUserContext.Provider>
  );
};

export const useOrgUser = (): OrgUserContextType & {
  refreshOrgUser: OrgUserRefreshContextType;
} => {
  const orgUser = useContext(OrgUserContext);
  const refresh = useContext(OrgUserRefreshContext);
  return {
    ...orgUser,
    refreshOrgUser: refresh,
  };
};
