import React, { useReducer } from "react";
import { isDev } from "../utils/system";
import type { ErrorStatus } from "../components/utils/ErrorPage";
import { ErrorPage } from "../components/utils/ErrorPage";

type ActionProps = {
  type: ActionType;
  errorStatus: ErrorStatus;
};

type ActionType = "SET";

const reducer: React.Reducer<ErrorStatus, ActionProps> = (state, { type, errorStatus }): ErrorStatus => {
  if (isDev) {
    console.group("State(Payment)");
    console.log("type: ", type);
    console.log("current: ", state);
    console.log("errorStatus: ", errorStatus);
    console.groupEnd();
  }

  switch (type) {
    case "SET":
      return errorStatus;
    default:
      return state;
  }
};

const initialState: ErrorStatus = {
  code: 200,
  message: "",
};

export const ErrorPageStateContext = React.createContext<ErrorStatus>(initialState);

export const ErrorPageDispatchContext = React.createContext<React.Dispatch<ActionProps>>((() => {
  // noop
}) as React.Dispatch<ActionProps>);

export const ErrorPageProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ErrorPageStateContext.Provider value={state}>
      <ErrorPageDispatchContext.Provider value={dispatch}>
        {state.code / 100 < 4 ? children : <ErrorPage code={state.code} message={state.message} />}
      </ErrorPageDispatchContext.Provider>
    </ErrorPageStateContext.Provider>
  );
};
