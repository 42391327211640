import type React from "react";
import { useCallback, useState } from "react";
import pick from "lodash/pick";
import type { ChatMessage, RoomMessage } from "../types";
import type { RoomActionProps, RoomStateProps } from "../contexts/RoomContext";

export interface ActionCreatorProps {
  sendChat: (message: ChatMessage) => void;
  receiveChat: (message: ChatMessage) => void;
  updateSelfStream: (stream: MediaStream | null, audioActive: boolean, videoActive: boolean) => void;
  setDeviceAngle: (angle: number) => void;
  sendSelfInfo: (to?: string) => void;
  setName: (name: string) => void;
  setMediaActive: ({ videoActive, audioActive }: { videoActive?: boolean; audioActive?: boolean }) => void;
}

export function useRoomActionCreators(
  state: RoomStateProps,
  dispatch: React.Dispatch<RoomActionProps>
): ActionCreatorProps {
  const [, setChatStates] = useState<{
    [memberId: string]: { timer?: number; lastNonFinalMessageReceivedAt?: Date; sendingNonFinalMessage: boolean };
  }>({});

  const updateRemoteSelfInfo = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (to: string, info: any) => {
      const message: RoomMessage = {
        action: "UPDATE_MEMBER",
        from: state.selfInfo.memberId || "UNDEFINED",
        to,
        payload: { target: state.selfInfo.memberId, info },
      };
      if (!state.client) {
        console.log("[VIDEO CHAT 0-3] cannot updateRemoteSelfInfo", message);
        return;
      }
      state.client.broadcastDataChannel(message);
    },
    [state]
  );

  const delayedSubmitTranscript = useCallback((memberId: string, chatMessage: ChatMessage) => {
    /*
      avoid sending too many messages to the server.
      submit the chatMessage to the server if
        - isFinal is true. (The message is fixed.)
        or
        - 5 seconds passed after the message.
    */

    setChatStates((states) => {
      const state = states[memberId] || { sendingNonFinalMessage: false };
      if (state.timer !== undefined) {
        clearTimeout(state.timer);
        state.timer = undefined;
      }
      if (chatMessage.content === "" && !state.sendingNonFinalMessage) {
        // don't send the empty chat message
      } else if (chatMessage.isFinal) {
        // doSubmitTranscript();
        state.lastNonFinalMessageReceivedAt = undefined;
        state.sendingNonFinalMessage = false;
      } else {
        if (!state.lastNonFinalMessageReceivedAt) {
          state.lastNonFinalMessageReceivedAt = new Date();
        }
        state.timer = window.setTimeout(() => {
          // doSubmitTranscript();
          setChatStates((states) => {
            const state = states[memberId];
            state.lastNonFinalMessageReceivedAt = undefined;
            state.sendingNonFinalMessage = true;
            states[memberId] = state;
            return states;
          });
        }, 5000 + state.lastNonFinalMessageReceivedAt.getTime() - Date.now());
      }
      states[memberId] = state;
      return states;
    });
  }, []);

  const sendChat = useCallback(
    (chatMessage: ChatMessage) => {
      const roomMessage: RoomMessage = {
        action: "CHAT",
        from: state.selfInfo.memberId || "UNDEFINED",
        to: "OTHERS",
        payload: chatMessage,
      };
      state.client?.broadcastDataChannel(roomMessage);

      dispatch({ type: "SEND_CHAT", payload: chatMessage });
      delayedSubmitTranscript(state.selfInfo.memberId, chatMessage);
    },
    [state.selfInfo.memberId, state.client, dispatch, delayedSubmitTranscript]
  );

  const receiveChat = useCallback(
    (chatMessage: ChatMessage) => {
      dispatch({ type: "RECEIVE_CHAT", payload: chatMessage });
      delayedSubmitTranscript(chatMessage.sendUser.memberId, chatMessage);
    },
    [dispatch, delayedSubmitTranscript]
  );

  const updateSelfStream = useCallback(
    (stream: MediaStream | null, audioActive: boolean, videoActive: boolean) => {
      state.selfInfo.sttClient?.setAudioStream(stream);
      dispatch({ type: "UPDATE_SELF_STREAM", payload: { stream, audioActive, videoActive } });

      navigator.mediaDevices.enumerateDevices().then((devices) => {
        console.log("updateSelfStream: enumerateDevices=", devices);
        const audioTrack = stream?.getAudioTracks()[0];
        const videoTrack = stream?.getVideoTracks()[0];

        const audioDevice = devices.find(
          (device) => device.kind === "audioinput" && device.label === audioTrack?.label
        );
        if (audioDevice) {
          dispatch({ type: "SWITCH_MIC", payload: { deviceId: audioDevice.deviceId } });
        }

        const videoDevice = devices.find(
          (device) => device.kind === "videoinput" && device.label === videoTrack?.label
        );
        if (videoDevice) {
          dispatch({ type: "SWITCH_VIDEO", payload: { deviceId: videoDevice.deviceId } });
        }
      });
    },
    [dispatch, state.selfInfo.sttClient]
  );

  const setDeviceAngle = useCallback(
    (deviceAngle: number) => {
      updateRemoteSelfInfo("OTHERS", { deviceAngle });
      dispatch({ type: "SET_DEVICE_ANGLE", payload: { deviceAngle } });
    },
    [dispatch, updateRemoteSelfInfo]
  );

  const sendSelfInfo = useCallback(
    (to?: string) => {
      updateRemoteSelfInfo(
        to || "OTHERS",
        pick(state.selfInfo, ["name", "videoActive", "audioActive", "isHost", "screenTrackIds"])
      );
    },
    [updateRemoteSelfInfo, state.selfInfo]
  );

  const setName = useCallback(
    (name: string) => {
      updateRemoteSelfInfo("OTHERS", { name });
      dispatch({ type: "SET_NAME", payload: { name } });
    },
    [dispatch, updateRemoteSelfInfo]
  );

  const setMediaActive = useCallback(
    ({ videoActive, audioActive }: { videoActive?: boolean; audioActive?: boolean }) => {
      if (videoActive === null || videoActive === undefined) {
        videoActive = state.selfInfo.videoActive;
      }
      if (audioActive === null || audioActive === undefined) {
        audioActive = state.selfInfo.audioActive;
      }
      state.client?.setSelfStream({
        audioActive,
        videoActive,
        micDeviceId: state.selfInfo.activeMicDeviceId,
        videoDeviceId: state.selfInfo.activeVideoDeviceId,
      });
      updateRemoteSelfInfo("OTHERS", { videoActive, audioActive });
      dispatch({ type: "SET_MEDIA_ACTIVE", payload: { videoActive, audioActive } });
    },
    [
      dispatch,
      updateRemoteSelfInfo,
      state.client,
      state.selfInfo.activeMicDeviceId,
      state.selfInfo.activeVideoDeviceId,
      state.selfInfo.videoActive,
      state.selfInfo.audioActive,
    ]
  );

  return {
    sendChat,
    receiveChat,
    updateSelfStream,
    setDeviceAngle,
    sendSelfInfo,
    setName,
    setMediaActive,
  };
}
